export const environment = {
  production: true,
  urlBackend: 'https://admin.sismat.com.ar',
  endpointBackend: 'https://admin.sismat.com.ar/api',
  gaCode: 'G-5XC5TXZ4GX',
  sentry: {
    environment: "production",
    dsn: "https://b26e6760a9544a558bfd453a9b57d9c6@o1185465.ingest.sentry.io/6304064",
    release: 'SISMAT_20241111-2'
  }
};
