import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base/base.service';

@Injectable({
    providedIn: 'root'
})
export class MercadopagoService extends BaseService {
    prefixPath = 'mercadopago';

    constructor(httpClient: HttpClient) {
      super(httpClient);
    }

    getPreferenceId(userId, planId = null, discount = 0): Observable<string> {
      return this.httpPost(`${this.prefixPath}/get-preference-id`, { user_id: userId, plan_id: planId, discount });
    }
}